<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <table
      :style="style"
    >
      <tbody>
        <tr>
          <td>
            <output
              class="pr-1"
              v-dompurify-html="label(0)"
            />
          </td>
          <td>
            <output
              class="px-1"
              v-dompurify-html="label(1)"
            />
          </td>
          <td>
            <output
              class="pl-1"
              v-dompurify-html="label(2)"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-autocomplete
              v-model="value0"
              :class="`mr-1 dropdown-singleselect${classes}`"
              :disabled="disabled"
              data-select-index="0"
              :items="options(0)"
              item-text="text"
              item-value="id"
              return-object
              autocomplete="on"
            >
              <template
                v-slot:selection="data"
              >
                <v-avatar
                  v-if="validColor(data.item.color)"
                  :color="data.item.color"
                  left
                  size="12"
                  class="mr-2 elevation-1"
                />
                {{ data.item.text }}
              </template>
              <template
                v-slot:item="data"
              >
                <v-list-item-avatar
                  v-if="validColor(data.item.color)"
                  :color="data.item.color"
                  size="12"
                  class="mr-2 elevation-1"
                />
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </td>
          <td>
            <v-autocomplete
              v-model="value1"
              :class="`mx-1 dropdown-singleselect${classes}`"
              :disabled="disabled"
              data-select-index="1"
              :items="options(1)"
              item-text="text"
              item-value="id"
              return-object
              autocomplete="on"
            >
              <template
                v-slot:selection="data"
              >
                <v-avatar
                  v-if="validColor(data.item.color)"
                  :color="data.item.color"
                  left
                  size="12"
                  class="mr-2 elevation-1"
                />
                {{ data.item.text }}
              </template>
              <template
                v-slot:item="data"
              >
                <v-list-item-avatar
                  v-if="validColor(data.item.color)"
                  :color="data.item.color"
                  size="12"
                  class="mr-2 elevation-1"
                />
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </td>
          <td>
            <output
              class="ml-1 combine_result"
              v-dompurify-html="value3"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeCombineSelects',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let value = builder.getCellValue(index, this.cell, this.clone, {
        'select_0': {},
        'select_1': {},
        result: ''
      })
      try {
        if (typeof value === 'string') {
          value = JSON.parse(value)
        }
      } catch (ex) {
        // Keine Aktion nötig
      }
      return value
    },
    value0: {
      get() {
        return this.selectValue(0)
      },
      set(optionValue) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let previousValue = builder.getCellValue(index, this.cell, this.clone, {
          'select_0': {},
          'select_1': {},
          result: ''
        })
        try {
          if (typeof previousValue === 'string') {
            previousValue = JSON.parse(previousValue)
          }
        } catch (ex) {
          // Keine Aktion nötig
        }
        const value = builder.extend()({}, previousValue)
        if (optionValue.hasOwnProperty('id')) {
          value['select_0'] = {
            id: optionValue.id,
            label: optionValue.text || ''
          }
        }
        this.updateResult(value)
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    },
    value1: {
      get() {
        return this.selectValue(1)
      },
      set(optionValue) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let previousValue = builder.getCellValue(index, this.cell, this.clone, {
          'select_0': {},
          'select_1': {},
          result: ''
        })
        try {
          if (typeof previousValue === 'string') {
            previousValue = JSON.parse(previousValue)
          }
        } catch (ex) {
          // Keine Aktion nötig
        }
        const value = builder.extend()({}, previousValue)
        if (optionValue.hasOwnProperty('id')) {
          value['select_1'] = {
            id: optionValue.id,
            label: optionValue.text || ''
          }
        }
        this.updateResult(value)
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          event: event,
          value: value,
          previousValue: previousValue
        })
      }
    },
    value3() {
      return this.value.result || ''
    }
  },
  methods: {
    options(index) {
      const appendix = index ? '_' + index : ''
      const optionsOriginal = this.cell.settings[`dropdown_builder${appendix}`] || []
      const optionsSorting = parseInt(this.cell.settings[`dropdown_sorting${appendix}`] || 0) || 0
      const options = []
      if (!Array.isArray(optionsOriginal)) {
        return options
      }
      for (let i = 0, option; (option = optionsOriginal[i]); i++) {
        if (typeof option === 'string') {
          option = {
            value: option,
            color: ''
          }
        }
        option.id = i
        option.text = this.optionLabel(option)
        options.push(option)
      }
      if (optionsSorting === 1) {
        options.sort(function(a, b) {
          if (a.text > b.text) {
            return 1;
          }
          if (a.text < b.text) {
            return -1;
          }
          return 0;
        })
      }
      if (optionsSorting === 2) {
        options.sort(function(a, b) {
          if (a.text > b.text) {
            return -1;
          }
          if (a.text < b.text) {
            return 1;
          }
          return 0;
        })
      }
      return options
    },
    optionLabel(option) {
      const builder = this.data.ChecklistBuilder
      return builder.getLocalization(option, 'value') || ''
    },
    validColor(color) {
      if (!color) {
        return false
      }
      if (/transparent/i.test(color)) {
        return false
      }
      if (/rgba\(([0-9]+, ){3}0\)/i.test(color)) {
        return false
      }
      return true
    },
    label(index) {
      return this.cell.settings[`inner_label_${index}`] || ''
    },
    selectValue(index) {
      const builder = this.data.ChecklistBuilder
      let value = this.value[`select_${index}`]
      try {
        if (typeof value === 'string') {
          value = JSON.parse(value)
        }
      } catch (ex) {
        value = []
      }
      value = parseInt(builder.getSelectPropertyToArray([value], 'id')[0])
      return value || 0
    },
    updateResult(value) {
      const value0 = value.select_0.label
      const value1 = value.select_1.label
      if (value0 === undefined && value1 === undefined) {
        return ''
      }
      const settings = this.cell.settings
      const operator = (settings.combine_sep_operator || '').trim()
      switch (operator) {
        case '+':
          value.result = (parseFloat(value0) || 0) + (parseFloat(value1) || 0)
          break
        case '-':
          value.result = (parseFloat(value0) || 0) - (parseFloat(value1) || 0)
          break
        case '*':
          value.result = (parseFloat(value0) || 0) * (parseFloat(value1) || 0)
          break
        case '/':
          if (!parseFloat(value1)) {
            value.result = 0
            return
          }
          value.result = (parseFloat(value0) || 0) / parseFloat(value1)
          break
        default:
          value.result = (value0 || '') + operator + (value1 || '')
          break
      }
    }
  }
}
</script>
